.main_div {
  padding: 40px;
}

.form_div {
  margin: 30px auto 0 auto;
  display: block;
  padding-bottom: 20px;
  width:500px;

  & input {
    width: 100%;
    height: 45px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(205, 203, 203);
    text-align: left;
    outline: none;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    margin: 10px 0;
  }

  & textarea {
    width: 100%;
    height: 100px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgb(205, 203, 203);
    text-align: left;
    outline: none;
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    margin: 10px 0;
  }

  & input::placeholder {
    color: #979797;
  }
  
  & textarea::placeholder {
    color: #979797;
  }

  & button {
    margin: 10px auto;
    display: inline-block;
    background-color: #00398E;
    color: white;
    border-radius: 5px;
    padding: 4px 16px;
    border: none;
  }
}