.main_div{
    max-width: 1300px;
    margin-inline: auto;
    text-align: left;
    padding-block: 50px;
    line-height: 2;
    & h4{
        font-weight: 600;
        font-family: "Inter", sans-serif;
    }
    & h5{
        font-size: 18px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    & p{
        font-size: 16px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        text-align: left;
        color: #5E6075;
     
    }
}
strong{
    font-weight: 600;
    color: black;
}
.inn_div{
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    cursor: pointer;
    & span{
        font-size: 16px;
    }
}
@media screen and (max-width : 1400px) {
   .main_div{
    padding-inline: 100px;
    max-width: auto;
   } 
}
@media screen and (max-width : 620px) {
.main_div{
    padding: 30px;
}
}