.upper_div {
    background: #fbfbfb;
    padding: 40px 160px 34px 160px;
    margin-top: 30px;
}

.main_div1 {
    display: flex;
    gap: 70px;
    margin: 20px 0;
    padding-bottom: 40px;
    align-items: center;
    /* border-bottom: 1px solid #d9d9d9; */
   
}

.main_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
    & input {
        width: 65%;
        height: 45px;
        background: white;
        padding: 10px;
        border-radius: 5px;
        border: none;
        text-align: left;
        outline: none;
        font-family: "Outfit", sans-serif;
font-size: 16px;
     }
     & input::placeholder{
        color: #979797;
     }
     & button {
        background-color: #00398E;
        color: white;
        border-radius: 5px;
        height: 45px;
        width: 15%;
        border: none;
     }
}

.main_div1>p:nth-child(1) {
    color: #00398E;
    font-weight: 500;
}

.main_div1 p {
    font-weight: 500;
    margin-bottom: 0;
}
.main_div p {
    font-weight: 500;
    margin-bottom: 0;
}

.div2 {
    display: flex;
    justify-content: space-between;
    /* margin: 20px 0; */
    align-items: center;
}

.div2>div {
    display: flex;
    gap: 20px;
    color: #25261D;
}

.div2>div>svg {

    color: #25261D;
}

.div2>p {
    font-size: 14px;
    font-weight: 500;
    color: #25261D;
    margin-bottom: 0;
    font-family: "Outfit", sans-serif;

}
.contactinfo{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-bottom: 30px;
}
.contactsection{
    & h3{
        font-size: 18px;
        font-weight: 500;
        font-family: "Outfit", sans-serif;
        text-align: left;
        margin-bottom: 0;
    }
    & p {
        font-size: 16px;
        font-family: "Outfit", sans-serif;
margin-bottom: 0;
    }
}
.top_contact{
    display: flex; 
    gap: 20px;
}
.icon{
    background: #DAEEFF;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00398E;
    font-size: 20px;
}
.icon1{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00398E;
    font-size: 20px;
    width: 80px;
    background: #DAEEFF;
    height: 50px;
}
.top_div{
    display: flex;
    gap: 20px;
    width: 280px;
}
.contact{
    text-align: left;
    & p{
        font-size: 13px;
        color: #929292;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
        font-family: "Outfit", sans-serif;

    }
     & a {
        font-size: 15px;
        font-weight: 500;
        color: black;
        text-align: left;
        text-decoration: none;
        font-family: "Outfit", sans-serif;

     }
     & address{
        font-size: 15px;
        font-weight: 500;
        color: black;
        text-align: left;
        font-family: "Outfit", sans-serif;

     }
}

@media screen and (max-width: 1400px){
.main_div1{
    gap: 3%
}
}
@media screen and (max-width : 1150px) {
    .upper_div {
        padding: 20px;
    }
}

@media screen and (max-width : 1000px) {
    .upper_div {
        width: 100%;
        padding: 40px;
    }
    .contactinfo{
        flex-direction: column;
    }

    .main_div {
        align-items: center;
        justify-content: space-between;
        gap: 27px;
    }
    .main_div1 {
        align-items: center;
        justify-content: space-between;
        gap: 27px;
    }

    .main_div>p {
        font-size: 14px;
    }
    .main_div1>p {
        font-size: 14px;
    }
    .contactsection{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 20px;    
    }
}

@media screen and (max-width : 620px) {
    .main_div {
        flex-direction: column;
        gap: 0;
        padding: 20px;
        & button {
            margin-top: 20px;
            width: 100%;
        }
        & input {
            width: 100%;
        }

    }
    .main_div1 {
        flex-direction: column;
        gap: 0;
        padding: 20px 0;

    }

    .div2 {
        flex-direction: column-reverse;
        margin-top: 20px;
        gap: 0;
    }

    .div2>p {
        margin: 20px 0;
    }

    .main_div p {
        margin: 10px;
    }
    .main_div1 p {
        margin: 10px;
    }

    .upper_div {
        padding: 0;
    }
    .contactsection{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 20px;    
    }
    .contactinfo{
        padding: 20px;
    }
    .top_div{
        width: auto;
    }
    .top_contact{
        /* padding: 20px; */
        flex-wrap: wrap;
        gap: 0;
        row-gap: 20px;
        justify-content: space-between;
    }
    .icon1{
        width: 50px;
    }
}