/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
text-align: center;
z-index: 1;
  /* padding: 0 90px; */
  /* max-width: 1500px; */
}

.btn:hover,
.btn:focus-visible {
  color: inherit !important;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.btn-close:focus {
  box-shadow: none !important;}
  .btn-close{
    font-size: 13px;
  }
  .accordion-button:focus{
    box-shadow: none !important;
    outline: none !important;
  }
  .accordion-button:not(.collapsed){
    background: none !important;

  }
  .accordion{
    --bs-accordion-border-width : 0 !important
  }
  .accordion-button::after{
    --bs-accordion-btn-icon-width: 1rem !important;

  }