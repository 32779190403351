.img{
    width: 83%;
    height: 600px;
}
.btm_btn{
    background: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    font-size: 14px; 
    gap: 15px;
    text-align: center;
    letter-spacing: 1.5px;
    /* height: 50px; */
    /* width: 200px; */
   
}
.btm_btn > span > span{
    font-size: 12px !important;
    display: flex;
    gap: 15px;
    
}
.btm_btn > span {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    row-gap: 0px;
}
.btm_btn_div{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.mb{
    margin-bottom: 0 !important;
}
.p{
    margin:13px 0 40px 0;
    font-size: 18px;
    color: #737373;
    font-family: "Inter", sans-serif;
}
.btn1{
    color: #5FA5F9;
    font-size: 18px;
    border: none;
    background: none;
    font-family: "Inter", sans-serif;
}
.btn1 > span {
    text-decoration: underline;
    font-family: "Inter", sans-serif;
}
.btn1 svg {
    font-size: 25px;
}
@media screen and (max-width : 880px) {
    .img{
        width: 100%;
        height: 500px;
        /* order: 1; */
        
    }
    .mb{
        margin-top: 50px !important;
    }
}
@media screen and (max-width : 620px) {
    .img{
        width: 100%;
        height: fit-content;
        order: 1;
        
    }
    .ai_home{
        display: flex;
        flex-direction: column;
    }
    .btm_btn_div{
        width: 100%;
        flex-direction: column;
        order: 2;
        margin: 40px 0;
    }
    .btm_btn{
        padding: 5px;
        font-size: 16px;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .btm_btn > span > span{
       font-size: 12px !important;
    }
 
}