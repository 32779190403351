
/* @import url(https://db.onlinewebfonts.com/c/365834cfa7beb7ca64c00476397ddc32?family=Helvetica+Neue+Medium+Extended); */

@font-face {
  font-family: "Helvetica Neue Medium Extended";
  src: url("Assets/fonts/365834cfa7beb7ca64c00476397ddc32.eot");
  src: url("Assets/fonts/365834cfa7beb7ca64c00476397ddc32.eot?#iefix")format("embedded-opentype"),
  url("Assets/fonts/365834cfa7beb7ca64c00476397ddc32.woff2")format("woff2"),
  url("Assets/fonts/365834cfa7beb7ca64c00476397ddc32.woff")format("woff"),
  url("Assets/fonts/365834cfa7beb7ca64c00476397ddc32.ttf")format("truetype"),
  /* url("fonts/365834cfa7beb7ca64c00476397ddc32.svg#Helvetica Neue Medium Extended")format("svg"); */
}

div::-webkit-scrollbar {
  display: none;
  
}


