/* CSS (ImageGrid.module.css) */
.imageGridContainer {
  text-align: center;
  margin: 20px;
  font-family: Arial, sans-serif;
}

.header h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.header p {
  font-size: 1rem;
  color: gray;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  justify-items: center;
}

.gridItem img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}