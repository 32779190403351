.navbar {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: hsl(0, 0%, 100%);
  z-index: 9;
  /* border-bottom: 2px solid #f9f9f9; */
}

.nav_div {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  
}
.nav_img{
    height: 64px;
    cursor: pointer;
   }


.search_bar {
  width: 40%;
  position: relative;
}

.search_bar>img {
  position: absolute;
  width: 20px;
  top: 8px;
  right: 13px;
  cursor: pointer;
}

.search_bar>input {
  width: 100%;
  height: 40px;
  border: 1px solid #d9d9d9;
  padding: 0 25px;
  outline: none;
  color: #b6b6b6;
  border-radius: 20px;
  text-align: left;
}

.search_bar input::placeholder {
  color: #b6b6b6;
}

.nav_body {
  display: flex;
  align-items: center;
}

.nav_btn {
  /* margin-right: 30px; */
  padding: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nav_btn1 {
  /* margin-right: 30px; */
  padding: 10px;
  /* gap: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.nav_btn>svg {
  color: #86BBE6;
}
.nav_btn1> div>svg {
  color: #86BBE6;
}

.nav_btn>p {
  font-weight: 600;
  margin-bottom: 0;
  width: max-content;
}
/* .nav_btn1>p {
  font-weight: 600;
  margin-bottom: 0;
  width: max-content;
} */





.sidebar_open_btn {
  border: none;
  margin-left: 10px;
  display: none;
  transition: 0.3s ease-in;
}

.sidebar_open_btn div {
  width: 20px;
  height: 3px;
  margin-bottom: 4px;
  background: #bdbdbd;
  display: block;
  border-radius: var(--br);
}

.sidebar_open_btn div:last-child {
  margin-bottom: 0;
}

.btm_div {
  background: #f9f9f9;
  padding: 10px 110px;
}

.btm_div ul {
  display: flex;
  gap: 5%;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.btm_div ul li a {
  color: #000;
  text-decoration: none;
}
.phn_res{
  display: none;
  width: max-content;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}

@media screen and (max-width:1000px) {
  .navbar {
    justify-content: end;
    padding: 0 20px;
  }

  /* .btm_div ul li {
    padding: 10px;
    border-bottom: 1px solid #989898;

  }
  .btm_div { 
    padding: 10px;
  }
  .btm_div ul{
    flex-direction: column;
    width: 100%;
    padding: 0;
  } */
.nav_btn1{
display: block;
}  
.btm_div {
    display: none;
  }

  .search_bar {
    display: none;
  }

  .nav_btn {
    display: none;
  }

  .phn_res {
    display: block;
  }


  .sidebar_open_btn {
    display: block;
  }

  .nav_width {
    width: 160px;
  }
}