
.bottom_section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0;
  

    & h4 {
        /* color: #b0b7ff; */
        font-family: "Helvetica Neue Medium Extended";
        font-size: 36px;
        font-weight: 500;
        padding-bottom: 20px;
        text-align: left;
        margin-bottom: 50px;
    }

    & span {
        color: #4e5774;
        font-size: 13px;
        font-weight: 400;
        font-family: "Be Vietnam Pro", sans-serif;
        text-align: left;
        display: flex;
        margin-inline: 0px ;
        padding-bottom: 0;
    }

    & p {
        color: #4e5774;
        font-size: 14px;
        font-weight: 400;
        font-family: "Be Vietnam Pro" sans-serif;
        /* margin-inline: 20px; */
        text-align: left;
    }

    & h5 {
        padding-top: 10px;
        color: #000;
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 18px;
        font-weight: 700;
        /* margin-inline: 20px; */
        text-align: left;
    }

    & a {
        color: #424db6;
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        font-family: "Be Vietnam Pro" , sans-serif;
        display: flex;
        /* margin-inline: 20px; */
        gap: 5px;
        align-items: center;
    }

    & img {
        width: 90%;
        height: auto;
        padding-top: 10px;
    }
}
.btn{
    background:none;
    border: none;
    color: #00398E;
    text-decoration: underline;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width : 1400px)  {
.bottom_section{
    padding-inline: 100px;
}
}
@media screen and (max-width : 620px)  {
    .div_head h4{
        margin-bottom: 50px;
    }
    .bottom_section{
        padding-inline: 20px;
    }
    .btn{
        /* margin-top: 40px; */
        margin-bottom: 40px;
    }
    
}