.main_div {
    display: flex;
    padding: 40px;
    align-items: center;
    gap: 30px;
    justify-content: space-evenly;
}

.main_div>img {
    /* width: 550px; */
    height: 500px;
}

.main_div>div {
    width: 50%;
}

.main_div>div>h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
}

.main_div>div>p {
    font-size: 18px;
    color: #737373;
    text-align: left;
    margin: 10px 0 20px 0;
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

/* .btm_btn{
    background: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 18px; 
    gap: 5px;
    text-align: center;
    letter-spacing: 1.5px;
   
    width: 200px;
}
.btm_btn > span > span{
    font-size: 12px !important;
    display: flex;
    gap: 10px;
    
}
.btm_btn_div{
    display: flex;
    gap: 20px;
} */

.btm_btn {
    background: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #fff;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    font-size: 16px;
    gap: 15px;
    text-align: center;
    letter-spacing: 1.5px;
    /* height: 50px; */
    /* width: 200px; */

}

.btm_btn>span>span {
    font-size: 11px !important;
    display: flex;
    gap: 15px;

}

.btm_btn>span {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    /* row-gap: 3px; */
}

.btm_btn_div {
    display: flex;
    gap: 20px;
    /* align-items: center; */
    /* justify-content: center; */
}

.mob_res {
    display: none;
}

@media screen and (max-width : 1000px) {
    .btm_btn_div {
        flex-direction: column;
        width: fit-content;
    }

    .btm_btn_div button {
        padding: 10px 30px;
        gap: 25px;
    }
}

@media screen and (max-width : 880px) {
    /* .btm_btn_div button {
        padding: 15px 30px;
        gap: 25px;
    } */
    .main_div{
        flex-direction: column;
    }
    .main_div>img:nth-child(1) {
        display: none;
    }
    .mob_res {
        display: block;
        width: 200px !important;
        height: 350px !important;
    }
    .main_div>div {
        width: 100%;
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    .btm_btn_div{
        flex-direction: row;
    }
}
@media screen and (max-width : 620px) {
    .main_div>img:nth-child(1) {
        display: none;
    }

    .main_div>div {
        width: 100%;
    }

    .main_div>div>p {
        font-size: 18px;
        text-align: center;
    }

    .main_div>div>h1 {
        margin: 20px 0;
        font-size: 30px;
        text-align: center;
    }

    .main_div {
        padding: 0;
        flex-direction: column;
    }

    .btm_btn_div {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .btm_btn {
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 5px;
        font-size: 14px;
    }

    .btm_btn>span>span {
        font-size: 12px !important;
    }

    .mob_res {
        display: block;
        width: 200px !important;
        height: 350px !important;
    }
    /* .btm_btn_div button {
        padding: 10px 30px;
        gap: 25px;
    } */
}