.main_div{
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin-inline: auto;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:30px;
	right:30px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
   display: none;
}
.float > svg{
    margin-top: 10px;
}

@media screen and (max-width : 620px) {
    .main_div{
        padding: 30px;
        padding-top: 10px;
    }
    .float{
        display: block;
    }
 }