.accord{
    border: none !important;
    border-bottom: 1px solid #d9d9d9 !important;
    /* margin-bottom: 20px; */
}
.para{
    text-align: left !important;
    color: #5E6075;
    font-size: 16px;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    padding-top: 0 !important;
}
.head > button{
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    /* padding-block: 0 !important; */
}
.foot{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0 100px 0;
}
.foot p{
    color: #5E6075;
    font-size: 16px;
}
.foot a{
    color: #105AB2;
    font-size: 16px;
}
@media screen and (max-width : 1400px) {
.accord_divv{
    padding-inline: 100px;
}
}
@media screen and (max-width : 620px) {
.foot{
    margin-bottom: 50px;
}
.accord_divv{
    padding-inline: 20px;
}
}
