.service_div{
    display: flex;
    /* padding: 20px; */
    justify-content: space-around;
}
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 350px !important;
}
.card > img {
    height: 100px;
    width: 100px;
}
.card > h4 {
    font-size: 20px;
    margin:20px;
    font-weight: 500;
    width: 234px;
    font-family: "Helvetica Neue Medium Extended";
}
.card > p {
    font-size: 16px;
    text-align: left;
    color: hsl(240deg 7.71% 27.34%);
    font-family: "Quicksand", sans-serif;
font-weight: 500;
}
.p2{
    margin-top: 45px ;
}
.p3{
    margin-top: 18px ;
}

@media screen and (max-width : 880px)  {
    .service_div{
        flex-wrap: wrap;
        padding: 30px;
    }
    .p2{
        margin-top: 0 ;
    }
    .p3{
        margin-top: 0;
    }
}
@media screen and (max-width : 620px)  {
    .service_div{
        flex-direction: column;
        padding: 0;
    }
    .card{
        width: 100% !important;
        padding: 0;
    }
    .p2{
        margin-top: 0 ;
    }
    .p3{
        margin-top: 0;
    }
    .card > p{
      margin-bottom: 0;
      text-align: center;
    }
    .card:nth-child(1){
     
        margin-bottom: 50px;
    }
    .card:nth-child(2){
        margin-bottom: 50px;
    }
    .div_head h4{
        margin-bottom: 50px;
    }
    
}